import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  a {
    color: lime;
}
`;

const Content = styled.div`
  width: 100%;
  height: 80%;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 20px;
  

  @media (max-width: 600px) {
    padding: 5px;
  }
`;

const Ul = styled.ul`
  overflow-x: auto;
  height: inherit;
  border: 2px solid darkgray;
  border-radius: 10px;


  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: lime;
  }
`;

const Li = styled.li`
  display: flex;
  flex-direction: column;
  text-align: start;
  word-break: break-word;
  margin: 10px 10px 20px;
  box-shadow: 1px 1px 1px 2px gray;
  gap: 5px;
  border-radius: 10px;
  padding: 10px;
  
  a {
    color: lime;
}

  @media (max-width: 760px) {
    font-size: 13px;
  }
`;

export default function Projects() {
  const [itemsApi, setItemsApi] = useState([]);

  useEffect(() => {
    let abortController = new AbortController();

    async function getGitHubAPI() {
      try {
        const response = await fetch('https://api.github.com/users/DevOfInfinity/repos');

        if (!response.ok) {
          throw new Error(response.status);
        }

        const data = await response.json();
        setItemsApi(data);
      } catch (error) {
        console.error(error);
      }
    }

    getGitHubAPI();

    return () => abortController.abort();
  }, []);

  return (
    <Container>
      <Content>
        <Ul>
          {itemsApi.map(item => (
            <Li key={item.id}>
              <h3>{item.name.toUpperCase()}</h3>
              <a href={item.html_url} target="_blank" rel="noopener noreferrer">
                {item.html_url}
              </a>
              <p>Data Criação: {new Date(item.created_at).toLocaleDateString('pt-BR')}</p>
            </Li>
          ))}
        </Ul>
      </Content>

      <footer>
        <span>*Mais projetos via <a href="https://www.linkedin.com/in/joão-nascimento-725785236/" target="_blank" rel="noopener noreferrer">LinkedIn</a></span>
      </footer>
    </Container>
  );
}
