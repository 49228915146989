import styled from 'styled-components';
import { Link } from 'react-router-dom';

// CSS LAYOUT PAGE

export const ContainerLayout = styled.div`
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid white;
  height: 100px;
  padding: 0 20px;
  position: fixed;
  width: 100%;
  background: black;
  

  #name {
    font-size: 20px;
    text-align: end;
    padding: 5px;

    strong {
      color: lime;
      font-weight: bold;
    }

    p {
      font-size: 15px;
    }
  }  

  #navLinks {
    display: inline-flex;
    align-items: center;

    ul {
      list-style: none;
      padding: 0;
    }

    li {
      display: inline-block;
      margin-right: 20px;
    }

    li a {
      transition: 0.3s;
      font-size: 25px;
      text-decoration: none;
      color: lime;
      padding: 10px;
    }

    /* Estilo para o link ativo (por exemplo, página atual) */
    li a.active {
      font-weight: bold;
    }
  }
`;



// CSS HOME PAGE

export const ContainerHome = styled.div`

  nav{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    }

  @media (max-width: 768px) {

    nav{
      flex-direction: column;
      justify-items: center;
      border-bottom: 2px solid lime;
      border-top: 2px solid lime;

    }

    IMG{
      width: 20rem;
      height: auto;
    }

    ButtonContainer{
      text-align: center;
    }
  }
   
    #about {
      max-width: 60%;
      height: auto;
      text-align: justify;
      padding-left: 30px;
      margin: 10px;

      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      padding-top: 100px;

    }

    @media (max-width: 768px) {
  
      #about {
        max-width: 100%;
        padding-left: 0;
        

        }
    }    
    
    #buttonContainer {
      margin: 20px;
    }
  

  #Name {
    font-size: 30px;
    margin-bottom: 10px;
    text-align: start;
  }

  #Name strong {
    font-size: 40px;
    font-weight: bold;
    color: lime;
  }

  span#profit {
    color: lime;
    font-size: 25px;
  }

  span#skills {
    font-weight: bold;
    color: lime;
  }

  p {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 10px;
    text-indent: 20px;
  }
`;

export const IMG = styled.img`
display: flex;
padding: 30px;
border-radius: 200px 20px 10px 10px;

max-width: 100%;
height: auto;
`;

export const ButtonContainer = styled.div`
  padding: 30px;
  text-align: start;

  @media (max-width: 768px) {
      text-align: center;
    }
`;

export const LinkButton = styled(Link)`
  background-color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  text-decoration: none;
  font-size: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

// CSS Contatos Page
export const CustonLink = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-size: 30px;
  line-height: 2;
  color: lime;
  align-items: center;
  justify-content: center;

  a {
    color: white;
    padding-left: 10px;
  }
`;
