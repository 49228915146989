import { Outlet, Link } from "react-router-dom";
import React from "react";
import { ContainerLayout, Header } from '../styles/styles';
import '../styles/global.css'

const Layout = () => {
    return (
        <ContainerLayout>
            <Header id="header">

                <div id='name'>
                    <strong>João Nascimento</strong>
                    <p>FullStack</p>
                </div>

                <nav id="navLinks">
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/projetos">Projetos</Link>
                        </li>
                        <li>
                            <Link to="/contato">Contato</Link>
                        </li>
                    </ul>
                </nav>
            </Header>
            <Outlet />
        </ContainerLayout >

    )
};

export default Layout;