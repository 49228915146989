import React from 'react';
import { FaLinkedin, FaGithub, FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import { CustonLink } from '../styles/styles';




function Contato() {
    return (
        <CustonLink>
            <div>
                <FaLinkedin /> -
                <a href="https://www.linkedin.com/in/joão-nascimento-725785236/" target="_blank" rel="noopener noreferrer">
                    LinkedIn
                </a>
            </div>
            <div>
                <FaGithub /> -
                <a href="https://github.com/DevOfInfinity" target="_blank" rel="noopener noreferrer">
                    GitHub
                </a>
            </div>
            <div>
                <FaWhatsapp /> -
                <a href="https://wa.me/351935062949" target="_blank" rel="noopener noreferrer">
                    WhatsApp
                </a>
            </div>
            <div>
                <FaEnvelope /> -
                <a href="mailto:joaolunascimento87@gmail.com">
                    E-Mail
                </a>
            </div>
        </CustonLink>
    );
}

export default Contato;
