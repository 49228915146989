import React from "react";
import { FaArrowRight } from "react-icons/fa";
import minhaImagem from "../assets/fotoLime.png";

import { ContainerHome, LinkButton, IMG, ButtonContainer } from '../styles/styles';

const Home = () => {
    return (
        <ContainerHome>
            <nav>
                <div id='about'>

                    <p id="Name">
                        Olá, sou <strong>João Nascimento</strong>,<br />
                    </p>
                    <p>Um Desenvolvedor <span id='profit'>Full Stack</span> apaixonado por inovação.</p>

                    <p>Ao longo da minha carreira, tive o privilégio de contribuir para projetos desafiadores
                        e empolgantes. Minha jornada me levou à Pós-Graduação em Engenharia de Software,
                        onde aprimorei minhas habilidades e conhecimentos em diversas áreas, incluindo
                        <span id='skills'> HTML, CSS, JavaScript, Node.js, React, TypeScript,
                            SASS/SCSS, LESS, Styled-components, CSS Modules, Redux, APIs,
                            Python, mySQL, Gdeveloper, Unity, SCRUM.</span></p>
                    <p>O que me motiva é a busca contínua por aprendizado e a exploração de novas tecnologias.
                        A transição de carreira me permitiu desenvolver habilidades profissionais valiosas, impulsionando-me em direção aos meus objetivos.
                        Minha abordagem é focada em resultados e estou comprometido em criar soluções
                        que impactem positivamente projetos e empresas. Estou sempre pronto para enfrentar
                        novos desafios e colaborar em projetos que busquem a excelência tecnológica.</p>
                    <p>Estou animado para fazer parte de equipes e projetos que compartilhem minha
                        paixão pela tecnologia e pela inovação. Vamos juntos construir um futuro incrível!
                    </p>
                </div>
                <div>
                    <IMG src={minhaImagem} alt="logo" />
                </div>
            </nav>
            <footer>
                <ButtonContainer>
                    <LinkButton to="/projetos" >PROJETOS <FaArrowRight /></LinkButton>
                </ButtonContainer>
            </footer>
        </ContainerHome>
    );
};

export default Home;
